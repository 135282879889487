import React from "react";
// import classNames from "classnames/bind";
// import { Tooltip } from "@mui/material";
import OnLoadAnimator from "../components/OnLoadAnimator.tsx";
import Header from "../components/Header.tsx";
import ClientRoll from "../components/ClientRoll.tsx";
// import joeAvatar from "../assets/images/joe-avatar.png";

export default function HomePage({ clients }) {
	return (
		<div id="page-content" className="home">
			<Header />
			<div className="splash">
				<OnLoadAnimator
					order={3}
					content={
						<p className="big">
							Based in New Jersey, Joe Stasio is a creative with decades of
							experience in generating{" "}
							<mark>
								top-shelf sales collateral, marketing materials, UI/UX,
								front-end web development, and even music and sound design.
							</mark>
						</p>
					}
				/>
				<OnLoadAnimator
					order={4}
					content={
						<p>
							Joe is obsessed with details and eager to help others present
							their best. During off hours, he enjoys spending time with his
							wife and two children, cooking, recording music and podcasts, and
							playing Rocket League.
						</p>
					}
				/>
			</div>
			<OnLoadAnimator order={6} content={<ClientRoll clients={clients} />} />
		</div>
	);
}
