import React from "react";
import OnLoadAnimator from "../components/OnLoadAnimator.tsx";
import Header from "../components/Header.tsx";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";

export default function ProjectsPage({ projects }) {
	const navigate = useNavigate();

	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

	const slugify = (str) =>
		str
			.toLowerCase()
			.trim()
			.replace(/[^\w\s-]/g, "")
			.replace(/[\s_-]+/g, "-")
			.replace(/^-+|-+$/g, "");

	function handleClick(destination) {
		navigate(destination);
	}

	return (
		<div id="page-content" className="projects">
			<Header />
			<div className="projects-grid">
				{Object.values(projects).map((project, index) => (
					<OnLoadAnimator
						key={index}
						order={index}
						content={
							<div
								className="project summary"
								onClick={() => {
									handleClick(`/project/${slugify(project.title)}`);
								}}
							>
								<div className="project-image">
									<Document
										file={"/portfolio/" + project.file}
										onLoadSuccess={console.log("test")}
									>
										<Page pageNumber={1} />
									</Document>
								</div>
								<div className="project-info">
									<h3>{project.title}</h3>
									{project.tags && (
										<ul className="tags">
											{Object.values(project.tags).map((tag, index) => (
												<li key={index} className="tag">
													{tag}
												</li>
											))}
										</ul>
									)}
								</div>
							</div>
						}
					/>
				))}
			</div>
		</div>
	);
}
