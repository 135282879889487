import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";

export default function OnLoadAnimator({ order, content }) {
	const [loadState, setLoadState] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setLoadState(true);
		}, 125 * order);
	});

	return (
		<div
			className={classNames("animate-me", {
				"animate-in": loadState,
			})}
		>
			{content}
		</div>
	);
}
