import React, { useCallback } from "react";

import OnLoadAnimator from "../components/OnLoadAnimator.tsx";
import ClientLogo from "../utilities/ClientLogo.tsx";

export default function ClientRoll({ clients }) {
	const shuffle = useCallback((clients) => {
		const shuffled = clients
			.map((client) => ({ client, sort: Math.random() }))
			.sort((a, b) => a.sort - b.sort)
			.map(({ client }) => client);
		return shuffled;
	}, []);

	return (
		<div className="clients-grid">
			{Object.values(shuffle(clients)).map((client, index) => (
				<OnLoadAnimator
					key={index}
					order={index}
					content={<ClientLogo key={index} client={client} />}
				/>
			))}
		</div>
	);
}
