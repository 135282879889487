import React, { useState } from "react";
import OnLoadAnimator from "../components/OnLoadAnimator.tsx";
import Header from "../components/Header.tsx";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

export default function ProjectPage({ project, client }) {
	const navigate = useNavigate();
	const [numPages, setNumPages] = useState(null);

	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

	const slugify = (str) =>
		str
			.toLowerCase()
			.trim()
			.replace(/[^\w\s-]/g, "")
			.replace(/[\s_-]+/g, "-")
			.replace(/^-+|-+$/g, "");

	function handleClick(destination) {
		navigate(destination);
	}

	return (
		<div id="page-content" className="project">
			<Header />
			<div className="project detail-view">
				<header>
					<div className="project-info">
						<h1>{project.title + " " + client.title}</h1>
						{project.description && <p>{project.description}</p>}
						{project.tags && (
							<ul className="tags">
								{Object.values(project.tags).map((tag, index) => (
									<li key={index} className="tag">
										{tag}
									</li>
								))}
							</ul>
						)}
					</div>
				</header>
				<main>
					<OnLoadAnimator
						order={1}
						content={
							<div
								className="project"
								onClick={() => {
									handleClick(`/project/${slugify(project.title)}`);
								}}
							>
								<div className="project-image">
									<Document
										file={"/portfolio/" + project.file}
										onLoadSuccess={({ numPages }) => setNumPages(numPages)}
									>
										{Array.apply(null, Array(numPages))
											.map((x, i) => i + 1)
											.map((page) => (
												<Page pageNumber={page} />
											))}
									</Document>
								</div>
							</div>
						}
					/>
				</main>
			</div>
		</div>
	);
}
