import React from "react";
import "./assets/css/main.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import DATA from "./data.json";
import HomePage from "./pages/Home.tsx";
import ProjectsPage from "./pages/Projects.tsx";
import ProjectPage from "./pages/Project.tsx";
import EmailSignature from "./pages/EmailSignature.tsx";

function App() {
	const projects = DATA["projects"];
	const clients = DATA["clients"];

	const slugify = (str) =>
		str
			.toLowerCase()
			.trim()
			.replace(/[^\w\s-]/g, "")
			.replace(/[\s_-]+/g, "-")
			.replace(/^-+|-+$/g, "");

	return (
		<div className="App" id="page">
			<BrowserRouter>
				<Routes>
					<Route
						path="/"
						element={
							<HomePage clients={clients.filter((client) => !client.hidden)} />
						}
					/>
					<Route path="/email-signature" element={<EmailSignature />} />
					<Route
						path="/projects"
						element={
							<ProjectsPage
								projects={projects.filter((project) => !project.hidden)}
							/>
						}
					/>

					{Object.values(projects).map((project, index) => (
						<Route
							key={index}
							path={"project/" + slugify(project.title)}
							element={
								<ProjectPage
									project={project}
									client={
										clients.find((client) => client.slug === project.client) ||
										[]
									}
								/>
							}
						/>
					))}
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
